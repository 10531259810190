
import { Vue, Component } from "vue-property-decorator";
import {EmpreendimentoService, SituacaoContratoService} from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgBoleto extends Vue {

  filter: {
    empreendimentoId: [];
    dataSituacaoInicial: string;
    dataSituacaoFinal: string;
    empresaId:[];
    dataEmissaoContratoInicial:string,
    dataEmissaoContratoFinal:string,
    situacaoContratoId:any,
  } = {
    empreendimentoId: [],
    dataSituacaoInicial: '',
    dataSituacaoFinal:'',
    empresaId:[],
    dataEmissaoContratoInicial:'',
    dataEmissaoContratoFinal:'',
    situacaoContratoId:[],
  }; 

    valid = true;
    empreendimentos: any = [];
    empresas: any = [];

    get selecionarTodosEmpreendimentos(){
        return this.filter.empreendimentoId.length === this.empreendimentos.length
    }

    get SelecionarAlgunsEmpreendimentos(){
        return this.filter.empreendimentoId.length > 0 && !this.selecionarTodosEmpreendimentos
    }

    get iconEmpreendimento(){
        if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
        if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    get textEmpreendimento(){
        if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
        if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
        return 'Selecionar todos'
    }

    SelecionarEmpreendimento() {
        this.$nextTick(() => {
            if (this.selecionarTodosEmpreendimentos) {
            this.filter.empreendimentoId = []
            } else {
            this.filter.empreendimentoId = this.empreendimentos.slice().map((x:any)=>x.id)
            }
        })
    }


    SelecionarEmpresa(){
      this.$nextTick(() => {
        if (this.selecionarTodosEmpresas) {
          this.filter.empresaId = []
        } else {
          this.filter.empresaId = this.empresas.slice().map((x:any)=>x.id)
        }
      })
    }
    get selecionarTodosEmpresas(){
      return this.filter.empresaId.length === this.empresas.length
    }

    get SelecionarAlgunsEmpresas(){
      return this.filter.empresaId.length > 0 && !this.selecionarTodosEmpresas
    }

    get iconEmpresa(){
      if (this.selecionarTodosEmpresas) return 'mdi-close-box'
      if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa(){
      if (this.selecionarTodosEmpresas) return 'Desmarcar'
      if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
      return 'Selecionar todos'
    } 

    SelecionarSituacao(){ 
       this.$nextTick(() => {
          if (this.selecionarTodosSituacao) {
          this.filter.situacaoContratoId = []
          } else {
            this.filter.situacaoContratoId = this.situacoes.slice().map((x:any)=>x.id)
          }
      })
    }

    get selecionarTodosSituacao(){
      return this.filter.situacaoContratoId.length === this.situacoes.length
    }

    get SelecionarAlgunsSituacao(){
      return this.filter.situacaoContratoId.length > 0 && !this.selecionarTodosSituacao
    }

    get iconSituacao(){
      if (this.selecionarTodosSituacao) return 'mdi-close-box'
      if (this.SelecionarAlgunsSituacao) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }

    get textSituacao(){
      if (this.selecionarTodosSituacao) return 'Desmarcar'
      if (this.SelecionarAlgunsSituacao) return 'Selecionando...'
      return 'Selecionar todos'
    }

    situacoes = [];

    mounted() { 
        new SituacaoContratoService().ListarTudo().then(
        res => {
            this.situacoes = res.data.items;
        });
 
        new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        res => {
            this.empresas = res.data.items;
         });

        new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
            this.empreendimentos = res.data.items;
        }); 
    } 

    Visualizar() {
        if((!this.filter.dataEmissaoContratoInicial || !this.filter.dataEmissaoContratoFinal) && (!this.filter.dataSituacaoInicial || !this.filter.dataSituacaoFinal)){
            this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos uma das datas.", "warning");
            return true;
        }      
        let routeLink = this.$router.resolve({ 
        name: "RelContratoSituacao",
            query: {
                empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : "",
                dataSituacaoInicial: this.filter.dataSituacaoInicial ? this.filter.dataSituacaoInicial.toString() : "",
                dataSituacaoFinal: this.filter.dataSituacaoFinal ? this.filter.dataSituacaoFinal.toString() : "",
                empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : "",
                dataEmissaoContratoInicial: this.filter.dataEmissaoContratoInicial ? this.filter.dataEmissaoContratoInicial.toString() : "",
                dataEmissaoContratoFinal: this.filter.dataEmissaoContratoFinal ? this.filter.dataEmissaoContratoFinal.toString() : "",
                situacaoContratoId: this.filter.situacaoContratoId ? this.filter.situacaoContratoId.toString() : ""
            },
        });
        window.open(routeLink.href, "_blank");
    }
}
